$height: 34px
$select-padding: 6px
$select-total-height: $height + $select-padding
$error-color: #a22
$header-height: 46px

// Outer wrapper that includes every input control
.rhfa-inputBlockWrap
  display: flex
  flex-flow: column nowrap
  margin-bottom: 16px
  // Input's label
  .rhfa-label
    flex: 0 auto
    margin-bottom: .25em
// Styles for the input itself (<input />)
.rhfa-input
  flex: 0 auto
  border: 1px solid #a0a0a0
  background-color: #ffffff
  border-radius: 4px
  padding: 0px 8px
  font-size: 16px
  // Input is normal
  &.rhfa-standard
    min-height: $height
  // Input has errors
  &.rhfa-errored
    border: 1px solid $error-color
  &.rhfa-errored:focus
    border: 1.5px solid $error-color
// Inlined controls wrapper
.rhfa-inlineWrap
  .rhfa-input
    min-width: 250px
    width: 100%

// Header for input arrays
.rhfa-itemHeader
  display: inline-block

// Wrapper for the array panel
.rhfa-inputPanelWrap
  display: flex
  flex-flow: row nowrap
  align-items: center
  width: 100%
  justify-content: space-between

// Simply a button
.rhfa-button
  min-height: $height
  height: $height
  line-height: 1em
  padding: 0px 8px
  border: 1px solid #b0b0b0
  // background-image: linear-gradient(#f0f0f0, #c0c0c0)
  border-radius: 4px
  margin: 0 4px
  cursor: pointer

  &[type="submit"]
    display: none

// Outer checkbox div
.rhfa-checkboxWrap
  margin-bottom: 16px

// Outer radio div
.rhfa-radioGroupWrap
  margin-bottom: 16px

// Label for checkbox and radio
.rhfa-checkboxLabel, .rhfa-radioLabel
  margin-left: 8px

// Text to add insight
.rhfa-helper
  position: relative
  font-size: 12px
  color: #606060
  padding: 4px

// Text to explain the error
.rhfa-error
  position: relative
  font-size: 12px
  color: $error-color
  padding: 4px

// Select input
.rhfa-select
  height: $select-total-height - $select-padding
  padding: $select-padding
  border: 1px solid #b0b0b0
  background: "#FFFDF9"
  border-radius: 4px

// Table for the input array table
.rhfa-table
  width: 100%
  thead
    display: none
  td
    vertical-align: top
    &:nth-child(1), &:nth-child(3)
      width: 0.1%
      white-space: nowrap

.rhfa-panel
  display: block
  margin-bottom: 8px
  border: 1px solid #AFDBD2
  border-radius: 8px
  overflow: hidden
  .rhfa-title
    background: #FFFDF9
    border-bottom: 2px solid #AFDBD2
    color: #202020
    min-height: $header-height
    line-height: $header-height
    padding-left: 8px
  .rhfa-content
    background: #ECEDE9
  .rhfa-contentMargin
    padding: 8px
  &.rhfa-errored
    border: 2px solid $error-color
