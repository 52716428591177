body,
html {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
  color-adjust: exact !important; /*Firefox*/
  image-rendering: crisp-edges;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100vh;
  width: 100vw;

  padding: 0;
}

body {
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
